import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { PageNotFound } from './pages/NotFound';
import { Careers } from './pages/Careers';
import { TermsOfService } from './pages/Terms';
import { ROCm } from './pages/ROCm';
import { Snapdragon } from './pages/Snapdragon';
import { RyzenAI } from './pages/RyzenAI';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <PageNotFound />,
  },
  {
    path: "rocm",
    element: <ROCm />,
    errorElement: <PageNotFound />,
  },
  {
    path: "snapdragon",
    element: <Snapdragon />,
    errorElement: <PageNotFound />,
  },
  {
    path: "ryzenai",
    element: <RyzenAI />,
    errorElement: <PageNotFound />,
  },
  {
    path: "careers",
    element: <Careers />,
    errorElement: <PageNotFound />,
  },
  {
    path: "terms",
    element: <TermsOfService />,
    errorElement: <PageNotFound />,
    children: [
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <div className="flex h-screen"> */}
    <RouterProvider router={router} />
    {/* </div> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
