import React, { useState } from 'react';

interface ToolTipProps {
  text: string;
  children: React.ReactNode;
}
const ToolTip: React.FC<ToolTipProps> = ({ text, children }) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const contentRef = React.useRef<HTMLDivElement>(null);

  const bias = 20;
  
  const handleMouseEnter = (e: React.MouseEvent) => {
    setShowToolTip(true);
  };

  // const handleMouseLeave = (e: React.MouseEvent) => {
  //   setShowToolTip(false);
  // };

  const handleMouseMove = (e: React.MouseEvent) => {
    contentRef?.current?.offsetWidth && setCoords({ x: e.pageX - contentRef?.current?.offsetWidth ?? 0 , y: e.pageY + bias});
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setShowToolTip(false)}
      onMouseMove={handleMouseMove}
    >
      {showToolTip && (
        <div
          className="rounded bg-black/80 text-white/90 p-2 text-xs"
          ref={contentRef}
          style={{
            position: 'fixed',
            opacity: ((coords.x === coords.y) && coords.x === 0) ? 0 : 1,
            left: `${coords.x}px`,
            top: `${coords.y}px`,
            zIndex: 1,
            width: 'fit-content',
            maxWidth: '300px',
          }}
        >
          {text}
        </div>
      )}
      {children}
    </div>
  );
};
export default ToolTip;