import { DISCORD_URL } from "../App";
import discordLogo from "../discord.svg";

import { classNames } from "../utils";
import { FiDownload} from "react-icons/fi";

const TopBar = () => {
  return (
    <div className={
      classNames("w-full h-[60px] justify-between flex flex-row items-center p-3 bg-[#01040A] border-b border-gray-200/20")
    }>
      <div className="flex flex-row space-x-1.5 font-medium items-center text-[1.05rem] cursor-pointer"
        onClick={() => window.location.href = "/"}
      >
        <span className="text-xl">👾</span><span>LM Studio</span>
      </div>

      <a href={DISCORD_URL} target="_blank" rel="noreferrer">
        <div className={
          classNames(
            "flex flex-row space-x-2 rounded border p-2.5 py-2.5 text-xs items-center",
            "bg-gray-700/50 hover:bg-blue-400/50 border-gray-600"
          )}>
          <img src={discordLogo} alt="Discord" className="w-5 h-5" />
          <span>Community</span>
        </div>
      </a>
    </div>
  );
}

const DownloadOption = ({ title, url, content, buttonTitle }
  : { title: string, url: string, content: JSX.Element, buttonTitle: string }) => {
  return ( 
    <div className={classNames("flex md:space-y-0 space-y-5 flex-col justify-between h-full p-3 rounded border-gray-400/70 border ")}>
      <div className="flex flex-col space-y-3 w-full">
        <p className={classNames(FireGradientTextColor, "font-bold")}>{title}</p>
        <div>{content}</div>
      </div>
      <div className="md:items-center items-end justify-end flex flex-col bg-blue-900/0">
      <a
        className="p-3 bg-blue-700 hover:bg-blue-800 w-full justify-center border border-gray-50/40 rounded flex flex-row whitespace-nowrap cursor-pointer items-center space-x-3"
        href={url}
      >
        <FiDownload /><span>{buttonTitle}</span>
      </a>
      </div>
    </div>
  )
}

const GPUBadge = ({ code, url} : { code: string, url?: string }) => {
  return (
    <a
    className="bg-blue-400/10 border border-blue-400/50 rounded p-2 text-xs text-white"
    href={
      url
    }
  >
    {code}
  </a>
  )
}

const FireGradientTextColor = "bg-gradient-to-r from-red-400 to-yellow-400 text-transparent bg-clip-text";

export const ROCm = () => {
  return (
    <div className="md:fixed md:inset-0 w-full h-full overflow-auto md:text text-sm">
      <div className="text-white p-0 h-full flex flex-col w-full bg-black justify-between">
        <TopBar />
        <div className="w-full justify-center flex flex-col items-center h-full px-3">
          <div className="w-full md:p-5 p-3 pt-10 justify-center flex flex-col text-xl items-center space-y-4">
          <h1 className={classNames(FireGradientTextColor, " font-medium text-3xl text-center ")}>LM Studio 0.2.28 with AMD ROCm</h1>
          <h2 className="font-mono text-xs p-1 rounded bg-gray-800 border border-gray-600 text-center">Technology Preview Release <span className="opacity-60">(Updated: 0.2.24 → 0.2.28)</span></h2>
          
          <div className="flex flex-row space-x-5 items-center w-full justify-center p-3 scale-90">
            <img className="block w-[65px] shadow" src="/assets/android-chrome-192x192.png" alt="LM Studio Logo" />
            <div>+</div>
            <img className="block w-[80px] shadow" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/ROCm_logo.png/250px-ROCm_logo.png" alt="AMD ROCm Logo" />
            <div>+</div>
            <img className="block w-[120px] shadow" src="https://user-images.githubusercontent.com/1991296/230134379-7181e485-c521-4d23-a0d6-f7b3b61ba524.png" alt="llama.cpp Logo" />
          </div>

          </div>
          <div className="flex max-w-[600px] flex-col bg-[#101827] rounded-xl border h-fit font-mono bg-gray-700/20">
            
            <div className={classNames(" bg-black/30 border-b w-full p-3 rounded-t-xl flex flex-row justify-between items-center")}>  
              <p className="p-0 m-0 font-medium font-mono text-blue-300">LM Studio</p>

              <p>AMD ROCm</p>

            </div>
            <div className="md:p-5 p-3 opacity-90 justify-between flex flex-col h-full bg-blue-400/0 md:space-y-10 space-y-5 ">
              <p>
                {/* <span className={classNames(FireGradientTextColor, isMobile ? "hidden" : "")}>Experience the seriously impressive performance of AMD ROCm with LM Studio.</span> */}
                <span className={classNames(FireGradientTextColor)}>
                  Run Llama, Mistral, Mixtral, and other local LLMs on your PC, leveraging the awesome performance of AMD ROCm. 
                </span>
                <span>{" "}Develop intuition about LLMs and what they can do. Experiment and build!</span>
              </p>

              <div>
                <p className="font-medium opacity-80 w-full text-center">
                  Supported GPUs
                  (<a href="https://rocm.docs.amd.com/en/docs-5.7.1/release/windows_support.html" target="_blank"
                      rel="noopener noreferrer" className="link-icon"
                      aria-label="Link to supported GPUs documentation">🔗</a>)
                </p>
                <div className="grid grid-cols-4 gap-3 p-5 pb-2 justify-center text-center items-center bg-blue-300/0">
                  <GPUBadge code="gfx1030"/>
                  <GPUBadge code="gfx1100"/>
                  <GPUBadge code="gfx1101"/>
                  <GPUBadge code="gfx1102"/>
                </div>
              </div>
            </div>


            <div className={classNames("bg-black/30 border-b border-t w-full p-3 flex flex-row justify-between")}>
              <p className="text p-0 m-0 font-medium font-mono text-blue-300">Downloads</p>
              <p className="text p-0 m-0 font-medium font-mono">LM Studio 0.2.28</p>
            </div>

            <div className={
              classNames(
                "flex justify-between max-w-2xl mx-auto flex-col space-y-5 p-3 py-5 w-full min-h-[200px]"
              )
            }>
              <DownloadOption
                title="LM Studio for Windows"
                buttonTitle="Download LM Studio with ROCm"
                url="https://files.lmstudio.ai/windows/0.2.28/beta/LM-Studio-0.2.28-ROCm-Preview.exe"
                content={
                  <div className="flex flex-col">
                    <div>One-click installer.</div>
                  </div>
                } />
            </div>  
          </div>
          <div className="md:text-center text-xs p-3 font-mono space-y-1.5 pt-5 opacity-80">
            <div>LM Studio 0.2.28 ROCm is a technology preview release provided under the <a href="/terms" className="text-blue-400">LM Studio Terms of Service</a>.</div>
            <div>Please make sure you read + agree to those before using the app.</div>
          </div>
        </div>
        <p className="text-center text-xs p-3 bg-[#01040A] border-t border-gray-200/20 text-gray-400/60 md:space-x-2 flex-col flex">
          <span>© 2024 Element Labs Inc. All rights reserved.</span>
          <span>All trademarks, logos, and brands are property of their respective owners.</span>
        </p>
      </div>
    </div>
  );
}