import { useEffect } from "react";
import { DISCORD_URL } from "../App";
import discordLogo from "../discord.svg";

import { classNames } from "../utils";
import { FiDownload} from "react-icons/fi";

const TopBar = () => {
  return (
    <div className={
      classNames("w-full h-[60px] justify-between flex flex-row items-center p-3 bg-[#01040A] border-b border-gray-200/20")
    }>
      <div className="flex flex-row space-x-1.5 font-medium items-center text-[1.05rem] cursor-pointer"
        onClick={() => window.location.href = "/"}
      >
        <span className="text-xl">👾</span><span>LM Studio</span>
      </div>

      <a href={DISCORD_URL} target="_blank" rel="noreferrer">
        <div className={
          classNames(
            "flex flex-row space-x-2 rounded border p-2.5 py-2.5 text-xs items-center",
            "bg-gray-700/50 hover:bg-blue-400/50 border-gray-600"
          )}>
          <img src={discordLogo} alt="Discord" className="w-5 h-5" />
          <span>Community</span>
        </div>
      </a>
    </div>
  );
}

const DownloadOption = ({ title, url, content, buttonTitle }
  : { title: string, url: string, content: JSX.Element, buttonTitle: string }) => {
  return ( 
    <div className={classNames("flex md:space-y-0 space-y-5 flex-col justify-between h-full p-3 rounded border-gray-400/70 border")}>
      <div className="flex flex-col space-y-3 w-full">
        <p className={classNames(FireGradientTextColor, "font-bold ")}>{title}</p>
        <div>{content}</div>
      </div>
      <div className="md:items-center items-end justify-end flex flex-col bg-blue-900/0">
      <a
        className={
          "p-3  md:text-base text-xs bg-blue-700 hover:bg-blue-800 w-full justify-center border border-gray-50/40 rounded flex flex-row whitespace-nowrap cursor-pointer items-center space-x-3"
        }
        href={url}
      >
        <FiDownload /><span>{buttonTitle}</span>
      </a>
      </div>
    </div>
  )
}

const FireGradientTextColor = "bg-gradient-to-r from-red-400 to-yellow-400 text-transparent bg-clip-text";

const Header = () => {
  return (
    <>
      <title>LM Studio for Snapdragon X Elite (ARM64) - Technology Preview</title>
      <meta name="description" content="Run local AI workloads with Llama 3, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC." />
      <meta property="og:title" content="LM Studio for Snapdragon X Elite (ARM64)" />
      <meta property="og:description" content="Run local AI workloads with Llama 3, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC." />
      <meta property="og:image" content="https://lmstudio.ai/assets/snapdragon.png" />
      <meta property="og:url" content="https://lmstudio.ai/snapdragon" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="LM Studio for Snapdragon X Elite (ARM64)" />
      <meta name="twitter:description" content="Run local AI workloads with Llama 3, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC." />
      <meta name="twitter:image" content="https://lmstudio.ai/assets/snapdragon.png" />
  </>
  )
}

export const Snapdragon = () => {
  useEffect(() => {
    // Update the document title
    document.title = "LM Studio for Snapdragon X Elite (ARM64) - Technology Preview";

    // Create and update meta tags
    const metaTags: Array<{ name?: string; property?: string; content: string }> = [
      { name: "description", content: "Run local AI workloads with Llama 3, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC." },
      { property: "og:title", content: "LM Studio for Snapdragon X Elite (ARM64)" },
      { property: "og:description", content: "Run local AI workloads with Llama 3, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC." },
      { property: "og:image", content: "https://lmstudio.ai/assets/snapdragon.png" },
      { property: "og:url", content: "https://lmstudio.ai/snapdragon" },
      { property: "og:type", content: "website" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: "LM Studio for Snapdragon X Elite (ARM64)" },
      { name: "twitter:description", content: "Run local AI workloads with Llama 3, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC." },
      { name: "twitter:image", content: "https://lmstudio.ai/assets/snapdragon.png" }
    ];

    metaTags.forEach(tag => {
      const attributeName = tag.name ? 'name' : 'property';
      const attributeValue = tag.name || tag.property;

      if (attributeValue) {
        let meta = document.querySelector(`meta[${attributeName}="${attributeValue}"]`);
        if (!meta) {
          meta = document.createElement('meta');
          meta.setAttribute(attributeName, attributeValue);
          document.head.appendChild(meta);
        }
        meta.setAttribute('content', tag.content);
      }
    });

    // Cleanup function to reset the title when component unmounts
    return () => {
      document.title = "LM Studio"; // Or whatever your default title is
      // Optionally remove or reset meta tags here
    };
  }, []);

  return (
    <>
      {false && <Header /> }
      <div className="md:fixed md:inset-0 w-full h-full overflow-auto md:text text-sm">
        <div className="text-white p-0 h-full flex flex-col w-full bg-black justify-between">
          <TopBar />
          
          <div className="w-full justify-center flex flex-col items-center h-full px-3 ">
            <div className="w-full md:p-5 p-3 pt-10 justify-center flex flex-col text-xl items-center space-y-4 ">
              <h1 className={classNames(FireGradientTextColor, " font-medium text-3xl text-center ")}>LM Studio for Snapdragon X Elite (ARM64)</h1>
              <h2 className="font-mono text-xs p-1 rounded bg-gray-800 border border-gray-600 text-center">Technology Preview Release <span className="opacity-60">(0.3.2)</span></h2>
              
              <div className="flex flex-row space-x-5 items-center w-full justify-center p-3 scale-90">
                <img className="block w-[65px] shadow" src="/assets/android-chrome-192x192.png" alt="LM Studio Logo" />
                <div>+</div>
                <img className="block w-[80px] shadow" src="https://www.qualcomm.com/etc.clientlibs/qcomm-martech/clientlibs/clientlib-react/resources/static/media/Snapdragon-Black-Gold.png.c21596f0f5ec398ebcf9.png" alt="Snapdragon Logo" />
              </div>

            </div>
            
            <div 
              className="flex max-w-[600px] w-full flex-col bg-[#101827] rounded-xl border h-fit font-mono ">
              
              <div className={classNames(" bg-black/30 border-b w-full p-3 rounded-t-xl flex flex-row justify-between items-center")}>  
                <p className="p-0 m-0 font-medium font-mono text-blue-300">LM Studio</p>

                <p className="opacity-70">Technology Preview</p>

              </div>
              <div className="md:p-5 p-3 opacity-90 justify-between flex flex-col h-full bg-blue-400/0 md:space-y-10 space-y-5 ">
                <div className="flex flex-col gap-3">
                  <span className={classNames(FireGradientTextColor, "text-lg")}>
                    Run local AI workloads with Llama 3.1, Phi-3, and other open-weights LLMs on your Snapdragon X Elite PC.
                  </span>
                </div>
              </div>


              <div className={classNames("bg-black/30 border-b border-t w-full p-3 flex flex-row justify-between")}>
                <p className="text p-0 m-0 font-medium font-mono text-blue-300">Downloads</p>
                <p className="text p-0 m-0 font-medium font-mono">LM Studio 0.3.2</p>
              </div>

              <div className={
                classNames(
                  "flex justify-between max-w-2xl mx-auto flex-col space-y-5 p-3 py-5 w-full "
                )
              }>
                <DownloadOption
                  title="LM Studio for Windows ARM64"
                  buttonTitle="Download LM Studio for Windows (ARM64)"
                  url="https://releases.lmstudio.ai/win32/arm64/0.3.2/1/LM%20Studio-0.3.2%20Setup.exe"
                  content={
                    <div className="flex flex-col gap-2 mb-2">
                      <div>One-click installer.</div>
                      <span className="opacity-50">Support CPU operation; NPU coming soon.</span>
                    </div>
                  } />
              </div>  
            </div>
            
            <div className="md:text-center text-xs p-3 font-mono space-y-1.5 pt-5 opacity-80 flex flex-col">
              <div>LM Studio for ARM64 is a technology preview release provided under the <a href="/terms" className="text-blue-400">LM Studio Terms of Service</a>.</div>
              <div>Please make sure you read + agree to those before using the app.</div>
            </div>
          </div>
          <p className="text-center text-xs p-3 bg-[#01040A] border-t border-gray-200/20 text-gray-400/60 md:space-x-2 flex-col flex">
            <span>© 2024 Element Labs Inc. All rights reserved.</span>
            <span>All trademarks, logos, and brands are property of their respective owners.</span>
          </p>
        </div>
      </div>
    </>
  );
}