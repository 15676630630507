import React, { useEffect, useState } from 'react';
import { classNames } from '../utils';

export function TermsOfService() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/terms.html')
      .then(response => response.text())
      .then(contents => setHtmlContent(contents))
  }, []);

  return (
    <div className={
    classNames(
        "bg-white h-full w-full flex flex-col", 
        "space-y-3 p-[50px] py-10 dark:bg-black dark:text-white text-black light:bg-white",
        )
    }
    >
    <div dangerouslySetInnerHTML={{ __html: htmlContent }}  />
    <br></br>
    <hr></hr>
    <Privacy />
    </div>);
}

export const TermsOfService1 = () => {
    return (
<div className="bg-white h-full w-full flex flex-col space-y-3 p-5 dark:bg-black dark:text-white text-black light:bg-white">
    <h1 className="font-bold text-xl">Terms of Service</h1>
    <h2 className="text-gray-400">Last updated: 08/12/2023</h2>
    <br></br>
    
    <p>Welcome to LM Studio, which is owned and operated by Element Labs, Inc., a Delaware corporation (together, the "Company," "we," or "us"). The Company has developed and makes available a desktop software application (the "Application") to search, download, and run large AI models (the "Services"). </p>

    <p>These Terms of Service (the "Agreement") govern your use of the Application, the Services.  This Agreement is effective when you commence using the Application and/or the Services (the "Effective Date"). This Agreement may be subject to change in the future.</p>
    
    <b>By accessing and using the Application and/or the Services, you agree to be bound by this Agreement. </b>
    <b>So, if you do not agree with anything in this Agreement, then please do not access, download, use, or purchase the Application and/or the Services</b>

    <br></br>
    <ol className="space-y-5">
        <li className="text-xl">1. Your Responsibilities</li>
        <ol className="list-item space-y-5 pl-5">
            <li className="font-bold">a. You confirm that you are accessing and using the Application and the Services, for your own personal, non-commercial use.  You likewise confirm you are not accessing and using the Application and/or the Services for any business, commercial, or organizational purpose.</li>
            <div>Use is <b>"personal and non-commercial"</b> if it is for the purpose of your own enjoyment and/or personal educational development. </div>

            <li className="font-bold">b. The Application and the Services are provided free of charge to individuals for personal non-commercial use. </li>
            <li><b>You confirm that you will not access or use the Application and the Services in your capacity as an employee, officer, director, contractor, founder, temporary worker, of any business or organization, or in the furtherance of any objective, monetary or otherwise, of any business or organization, without entering into a Supplemental Agreement signed by us and by you. </b></li>

            <li><b>c. You can learn more about entering into a Supplemental Agreement for business, commercial, and/or organizational use of the Application and the Services, by contacting </b><a className="underline" href="mailto:business@lmstudio.ai">business@lmstudio.ai</a>.</li>

            <li><b>d. You confirm that you remain bound by this Agreement, even if you have contacted </b><a className="underline" href="mailto:business@lmstudio.ai">business@lmstudio.ai</a><b> pursuant to Section 1.c., above, unless and until a Supplemental Agreement is duly executed and signed by us and by you.</b> In the event of a conflict between provisions arising out of this Agreement, the Supplemental Agreement will take precedence, unless expressly stated otherwise.</li>

            <li><b>e. You confirm that you are at least 13 years old and meet the minimum age of digital consent</b> in your country.</li>

            <li><b>f.  You confirm that you will not directly or indirectly</b>:</li>

            <ol className="list-item space-y-5 pl-5">
                <li>(i) reverse engineer, decompile, disassemble, modify, create derivative works of or otherwise create, attempt to create or derive, or permit or assist any third party to create or derive, the source code underlying the Application, https://lmstudio.ai/ (the "Website"), and/or the Services;</li>
                <li>(ii) attempt to probe, scan or test the vulnerability of the Application, the Website, and/or the Services, breach the security or authentication measures of the Application and the Services without proper authorization or willfully render any part of the Application and the Services unusable;</li>
                <li>(iii) use or access the Application, the Website, and/or the Services to develop a product or service that is competitive with the Company's products or services or engage in competitive analysis or benchmarking;</li>
                <li>(iv) transfer, distribute, resell, lease, license, or assign the Application and the Services or otherwise offer the Application and the Services on a standalone basis;</li>
                <li>(v) make calls through any current or future API that exceed limits on the number and frequency of such calls, or take any action that imposes an unreasonable or disproportionately heavy load on the API or the Application and the Services or that negatively affects the ability of others to access or use the API or the Application and the Services;</li>
                <li>(vi) introduce any virus, worm, Trojan horse, malware, or other malicious code through the API or to the Application, the Website, and the Services;</li>
                <li>(vii) otherwise use the Application and the Services in violation of applicable law (including any export law) or outside the scope expressly permitted in this Agreement.</li>
            </ol>
        </ol>    
    
        <li className="text-xl">2. Liability</li>
        <ol className="list-item space-y-5 pl-5">
            <li>a. <b>The Company (or any of our affiliates, subsidiaries, contractors, licensors, officers, directors, agents, or employees ("Related Parties")) will not be liable</b> for any indirect, incidental, consequential, punitive, special, or other similar damages, including loss of revenue, profits, data, benefits, or savings, whether or not due to the fault or negligence of the company or related parties, and regardless of whether either of us or our related parties have been advised of the possibility of such damages or losses. </li>
            <li>b. The Company is in no way responsible for content generated by LLMs used through the Application or Services.</li>
            <li>c. You are solely responsible for the models you choose to use through the Application.</li>
        </ol>

        <li className="text-xl">3. Indemnity</li>
        <ol className="list-item space-y-5 pl-5">
            <li>a. <b>You are solely and exclusively responsible for your use</b> of the Application and the Services.</li>

            <li>b. <b>You agree to indemnify, defend and hold harmless us and Related Parties</b> from all claims, liability, and expenses, including attorney's fees, arising out or in connection with your use of (or inability to use) the Application and the Services, including but not limited to your violation of these Terms, applicable law or regulation, any Content or data posted or used by you, or any other party’s use of any Service with your credentials, unless arising directly from the Company’s own fraud or willful or criminal misconduct.</li>
        </ol>

        <li className="text-xl">4. Disclaimer of Warranties</li>
        <ol className="list-item space-y-5 pl-5">
            <li>a. <b>We make no warranties or representations</b> about the Application and the Services.</li>

            <li>b. Except as expressly provided in this Agreement, and to the fullest extent permitted by law, <b>the Application and the Services are provided "as is" and "as available."</b></li>

            <li>c. <b>We disclaim all warranties or guarantees of any kind, express or implied, </b> whether arising under any law or from any usage in trade, or otherwise, including but not limited to the implied warranties of merchantability, non-infringement, quiet enjoyment, fitness for a particular purpose, or otherwise.</li>

            <li>d. <b>We further disclaim all warranties or guarantees </b> about the accuracy, reliability or benefits of the Application and the Services, artificial intelligence, models or any other technology, or that the Application and the Services will meet your requirements, be secure, uninterrupted or available at any time or location, or error-free, viruses-free, or that any errors will be corrected, or otherwise.</li>

            <li>e. <b>You will be solely responsible for any damage resulting </b> from your use of or access the Application and the Services, your downloading of data, or use of any other material provided by or through the Services.</li>
        </ol>

        <li className="text-xl">5. Severability</li>
        <ol className="list-item space-y-5 pl-5">
            <li>a. If any provision of this Agreement, by action of law or for any other reason, is held to be prohibited, invalid, void or unenforceable in any relevant jurisdiction, such provision will be stricken, and <b>the remaining provisions of this Agreement will remain in full force and effect</b>.</li>
        </ol>
    </ol>
    <br></br>
    <hr></hr>
    <Privacy />
</div>


    )
}

const Privacy = () => {
    return (
    <>
    <h1 className="text-xl pt-5" id="no-data-collection">LM Studio - additional details</h1>
    <br></br>
    <b>No data collection:</b>
    <p>	- The app does NOT monitor, collect, store, nor share any app usage data.</p>
    <br></br>
    <b>Using LM Studio at work</b>
    <p>Get in touch to discuss: team@lmstudio.ai</p>
    <br></br>
    <b>Web requests:</b>
        <p>- Version update check: upon opening the app, it performs a web request to the LM Studio "versions." endpoint to determine whether a new version update is available.</p>
        <p>- Auto-update: when a new update is available, the app downloads it automatically from LM Studio's S3 bucket.</p>
        <p>- Model catalog update: upon visiting the Home tab in the app, the app makes a web request to fetch a JSON file from https://github.com/lmstudio-ai/model-catalog (catalog.json)</p>
        <p>- Search or download models: upon searching / downloading models, the app makes web requests to https://huggingface.co</p>
    <br></br>
    <b>Dependencies:</b>
    <p>- The app utilizes llama.cpp (https://github.com/ggerganov/llama.cpp), ggml (https://github.com/ggerganov/ggml), Electron (https://github.com/electron/electron), and numerous 'npm' packages. The app is not responsible for any issue arising from these dependencies.</p>
    <br></br>
    <b>App data cached on the filesystem:</b>
    <div>- Chat data and metadata, chat persistence data, model configuration data, and other metadata required for the operation of the app is stored on the file system.
         These file are in your complete control. 
        <p>- The filesystem paths in which these files are stored are accessible through the app or through your operating system's file explorer or terminal.</p>
    </div>
    </>
    )
}