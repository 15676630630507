import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';


import './App.css';

import { FaLink } from 'react-icons/fa'; // Import a link icon from react-icons

import discordLogo from "./discord.svg";
import twitterLogo from "./twitter.svg";
import githubLogo from "./github.svg";
import emailLogo from "./email.svg";

// import demoGif from "./GPU-demo-smaller.gif"; //"./wizard_lm_demo_smaller.gif";
import demoGif from "./demo2.gif"; //"./wizard_lm_demo_smaller.gif";

import { classNames, badgeForArchitecture, Architecture } from './utils';
import ToolTip from './ToolTip';
import { isWindowsARM } from './utils';

const LATEST_VERSION = "0.3.2";

const InfoSymbol = ({classes} : { classes?: string}) => {
  return (
    <span className={classes}>ⓘ</span>
  );
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}

export const LinkWithImage = ({href, image, alt, title, children, classes, hideChildren}: {href:string, image:string, alt:string, title: string, children?:any, classes?: string, hideChildren?:boolean}) => {
  return (
    <a
      target="_blank" rel="noreferrer" href={href} title={title}
      className={
        classNames("transition ease-in-out-300  bg-gray-400/10 hover:bg-blue-400/20 hover:ring-1 hover:ring-white/50 text-white/70 hover:text-white px-2 w-fit py-2 rounded-md flex flex-row space-x-2 items-center", classes ? classes : "")
      }>
      {image !== "" && <img src={image} alt={alt} className="w-5" /> }
      {!hideChildren && <div>{children} </div> }
    </a>
  )
}

const GOOGLE_FORM_URL = "https://forms.gle/im6P9EBhVymtVtiB8";
export const DISCORD_URL = "https://discord.gg/aPQfnNkxGC";
const TWITTER_URL = "https://twitter.com/LMStudioAI";
const GITHUB_URL = "https://github.com/lmstudio-ai";
const MAIL_TO_URL = "mailto:team@lmstudio.ai";

const LATEST_MAC_VERSION = LATEST_VERSION;
const LATEST_WINDOWS_VERSION = LATEST_VERSION;
const LATEST_LINUX_VERSION = LATEST_VERSION;

// const heroBarBg = "bg-[#1F2429]";
// const heroBarBg = "bg-gradient-to-b from-[#631BB6] to-[#321567]"; // "bg-gradient-to-r from-[#323841] to-[#545D6A]";
const heroBarBg = "bg-[#1F263C]";
const bottomPageBg = "bg-[#24292E]"; //"bg-gray-300";
// const leftPaneBg = "bg-[#24292E]";
const titleTextColor = "text-purple-100";// "text-[#AE8EE9]";
const subtitleTextColor = "text-white"; // "text-purple-400";//"text-[#AE8EE9]";
const tertiaryTextColor = "text-[#949DA5]";

// const primaryTextColor = "text-white";
// const secondaryTextColor = "text-white/80";

const ggmlLink = (<a href="https://github.com/ggerganov/ggml" target="_blank" rel="noreferrer" className="underline hover:text-indigo-400">ggml</a>);
const exampleModels = (<span className="text-slate-400/80">(Llama 2, Orca, Vicuna, Nous Hermes, WizardCoder, MPT, etc.)</span>);

const SocialChannels = () => {
  return (
    <div className="space-x-3 text-white w-full items-center text-center flex flex-row justify-center p-2 ">

      <LinkWithImage href={TWITTER_URL} title="LM Studio's Twitter. Opens in the browser" image={twitterLogo} alt="Twitter" >
        <p className="text-[13px]">Twitter</p>
      </LinkWithImage>

      <LinkWithImage href={GITHUB_URL} title="LM Studio's Github. Opens in the browser" image={githubLogo} alt="Github" >
        <p className="text-[13px]">Github</p>
      </LinkWithImage>
      
      <LinkWithImage href={DISCORD_URL} title="LM Studio's Discord. Opens in the browser" image={discordLogo} alt="Discord" >
        <p className="text-[13px]">Discord</p>
      </LinkWithImage>

      <LinkWithImage href={MAIL_TO_URL} title="LM Studio's Discord. Opens in the browser" image={emailLogo} alt="Email" >
        <p className="text-[13px]">Email</p>
      </LinkWithImage>

    </div>
  );
}

const WhatsNewBanner = () => {
  return (
    <>
    <div className="flex flex-col items-center space-y-3 py-2 m-3 text-center">
      <p className="p-1 px-2 rounded border-emerald-300/70 text-emerald-300 border w-fit">
       🔥 New in <b>v0.3.0</b>: Chat with documents, UI refresh, Structured Output API, and so much more! <a href="https://lmstudio.ai/blog/lmstudio-v0.3.0" className="underline">Read the Announcement</a> 🎉
       </p>
    </div>
    </>
  );
}

const DocsBanner = () => {
  return (
    <>
    <div className="flex flex-col items-center space-y-3 py-2">
      {/* <p className="p-1 px-2 rounded border-emerald-300/70 text-emerald-300 border w-fit"> */}
      <p className="p-1 px-2 rounded border-slate-300/70 text-slate-300 border w-fit">
      {/* 🎉 New in <b>v{LATEST_VERSION}</b>: Bug Fixes + Multi-Model Sessions and JSON Mode! */}
      📚 Visit LM Studio Docs: <a href="docs" className="underline">https://lmstudio.ai/docs</a>
      </p>
    </div>
    </>
  );
}


const DownloadActions = () => {
  const [isARM, setIsARM] = useState<boolean | null>(null);

  useEffect(() => {
    isWindowsARM().then(setIsARM);
  }, []);

  return (
    <div className="md:max-w-[400px] w-full flex flex-col gap-y-2 p-1">
      {/* {isMobile ?
      <>
      <p className="text-white text-md px-2 py-1 rounded bg-gray-200/10">Visit website on desktop for download links</p>
      </>
      : */}
      <>
      <DownloadButton
        title={`Download LM Studio for Mac (M1/M2/M3)`}
        versionString={LATEST_MAC_VERSION}
        platformIcon={<img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg" alt="Apple Logo" className="w-3.5"/>}
        dlUrl={`https://releases.lmstudio.ai/darwin/arm64/0.3.2/2/LM-Studio-0.3.2-arm64.dmg`}
      />
      
      { isARM ? 

        <DownloadButton
          title={`Download LM Studio for Windows (ARM64)`}
          versionString={"Beta"}
          platformIcon={<img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" alt="MSFT Logo" className="w-3.5"/>}
          dlUrl={`https://lmstudio.ai/snapdragon`}
        />
        : 
        <DownloadButton
          title={`Download LM Studio for Windows`}
          versionString={LATEST_WINDOWS_VERSION}
          platformIcon={<img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" alt="MSFT Logo" className="w-3.5"/>}
          dlUrl={`https://releases.lmstudio.ai/win32/x64/0.3.2/2/LM-Studio-0.3.2-Setup.exe`}
        />
        
      }
      

      <DownloadButton
        title={`Download LM Studio for Linux`}
        versionString={LATEST_LINUX_VERSION}
        platformIcon={<img src="https://upload.wikimedia.org/wikipedia/commons/3/35/Tux.svg" alt="Linux Logo" className="w-3.5"/>}
        dlUrl={`https://releases.lmstudio.ai/linux/x86/0.3.2/1/LM_Studio-0.3.2.AppImage`}
      />

      
      </>

      <div className="p-2">
        <div className="text-white/60 text-xs space-y-1">LM Studio is provided under the <a href="terms" className="underline">terms of use</a>.</div>
      </div>
      {/* }  */}
    </div>
  );
}

const SupportedModelsView = () => {
  const isMobile = useIsMobile();

  return (
    <div>
      {!isMobile ? 
      <ToolTip text="Any model file in one of the supported architectures converted to `gguf` will work in LM Studio. `gguf` is a file format and `llama.cpp` is the name of a software library that enables running Large Language Models on consumer hardware.">
        <div className="text-sm flex flex-row space-x-2 items-center text-gray-300 whitespace-nowrap">
          <span className="">Run any</span>
          {badgeForArchitecture(Architecture.LLAMA, true)}
          {badgeForArchitecture(Architecture.MPT, true)}
          {badgeForArchitecture(Architecture.FALCON, true)}
          {badgeForArchitecture(Architecture.STARCODER, true)}
          {badgeForArchitecture(Architecture.REPLIT, true)}
          {badgeForArchitecture(Architecture.GPT_NEO_X, true)}
          <span><code>gguf</code> <InfoSymbol /></span> <span>models from Hugging Face</span>
        </div>
      </ToolTip> : 
      <div className="flex flex-col space-y-3 text-white text-xs">
        <p>Enabled Model Architectures:</p>
        <div className="text-sm flex flex-row space-x-2 items-center text-gray-300">
          {badgeForArchitecture(Architecture.LLAMA, true)}
          {badgeForArchitecture(Architecture.MPT, true)}
          {badgeForArchitecture(Architecture.STARCODER, true)}
          {badgeForArchitecture(Architecture.REPLIT, true)}
          {badgeForArchitecture(Architecture.GPT_NEO_X, true)}
        </div>
    </div>
      }
    </div>
  );
}

// const LatestReleaseView = () => {
//   return (
//     <div className="bg-black/0 text-white rounded px-3 py-1 space-x-1 rounded bg-blue-500/10 border border-white/40">
//       <span className="py-0.5 p-2 opacity-80">Latest Release:</span>
//       <span className="font-mono py-0.5 p-2 rounded ">v{LATEST_VERSION}</span>
//       {/* <span className="py-0.5 p-2 opacity-80">(Llama 2 supported)</span> */}
//     </div>
//   );
// }

const LeftPane = () => {
  const isMobile = useIsMobile();

  return (
    <div className={classNames("xl:w-[85%] w-full justify-center items-center flex flex-col text-center p-3")}>
      <div className="space-y-6 text-center w-full flex flex-col  h-full items-center justify-center">
        <div className="space-y-4">
          { isMobile && 
            <div className="p-2">
              <WhatsNewBanner /> 
              {/* <DocsBanner /> */}
            </div>
          }
          
          <div className="flex flex-row items-center justify-center space-x-2 text-left ">
            <img className="block w-10 shadow" src="/assets/android-chrome-512x512.png" alt="LM Studio Logo" />
            <div className={classNames("md:text-2xl text-xl font-medium", titleTextColor)}>LM Studio</div>
          </div>

          <div className={classNames("2xl:text-4xl text-4xl flex flex-col font-light space-y-3 p-5 ", subtitleTextColor)}>
            <p>Discover, download, and run local LLMs</p>
          </div>
          { 
      isMobile && 
      <img className="hero-img block mx-auto w-full rounded-xl m-3 mt-5 border border-gray-300/50" src={demoGif} alt="Demo" /> 
      }

          {
          // !isMobile
          false
           && 
          <div className=" text-white rounded px-3 lg:py-1 py-3 space-x-1 bg-black/20 border border-white/40 flex flex-row justify-center mx-auto items-center">
            <div className="flex lg:flex-row flex-col items-center lg:space-x-2 lg:space-y-0 space-y-2">
              <span>Supports</span>
              <code className="text-sm bg-cyan-500/20 rounded border border-white/10 p-0 px-1 whitespace-nowrap lg:w-fit w-full">MetaAI's Llama 2 🦙 (7B, 13B, 34B, 70B)</code>
              {/* {!isMobile && <span> and</span>} */}
              <code className="text-sm bg-purple-500/20 rounded border border-white/10 p-0 px-1 whitespace-nowrap lg:w-fit w-full">MistralAI's Mistral</code>
              
              {/* {!isMobile && <span> models</span>} */}
              </div>
          </div>
          }
        </div>

        <SupportedModelsView />
        <div className="space-y-2">
          <div className="p-1 px-2 mb-0 mt-3 border border-emerald-400/70 hover:border-cyan-200 rounded text-emerald-200 hover:text-cyan-200 hover:bg-blue-200/10 duration-300 ease-in-out transition-all text-md items-center space-x-2 flex flex-row">
            <div className="whitespace-nowrap">LM Studio 0.3.0 is finally here! 🥳🥳🥳</div> 
          </div>
        </div>

        {/* <div className="p-1 px-2 mb-0 mt-3 border border-green-400/70 hover:border-green-200 rounded text-green-300 hover:text-green-200 hover:bg-blue-200/10 duration-300 ease-in-out transition-all text-xs items-center space-x-2 flex flex-row">
          <div className="">Microsoft's <b>Phi-3</b> and MetaAI's <b>Llama 3</b> are supported in LM Studio 0.2.21 !</div> 
        </div> */}

        <DownloadActions />

        {/* {!isMobile && 
        <LatestReleaseView /> 
        } */}
        
      </div>
    </div>
  );
}

const DownloadButton = ({title, platformIcon, dlUrl, versionString}: {title: string, platformIcon: string|React.ReactNode, dlUrl: string, versionString: string}) => {
  const isMobile = useIsMobile();

  const styleClasses = classNames(
    "flex hover:bg-blue-400 bg-indigo-700 rounded-lg",
    "transition ease-in-out-300 text-white hover:text-blue-700",
    "flex flex-row items-center justify-center md:flex-grow",
    "no-underline cursor-pointer",
    "flex flex-row p-2.5 space-x-4 items-center",
    "text-sm font-medium"
    );

  return (
    <a href={dlUrl} className="">
      <div className={classNames(styleClasses, "flex", " border-white/30 border justify-middle xl:w-fit xl:min-w-[380px] pl-5")}>
        <div className="flex flex-row space-x-2.5 items-center w-full">
          <span className="text-xl">{platformIcon}</span>
          {isMobile ? <span className="text-left text-xs">{title}</span> :
          <span className="text-[0.98em]">{title}</span>}
        </div>
        <span className="text-sm font-normal opacity-50">{versionString}</span>
      </div>
    </a>
  )
}

const RightPane = () => {
  const isMobile = useIsMobile();

  return (
    <div className="flex flex-col items-center text-center font-sans w-full md:p-5 ">
      { !isMobile &&
      <>
      <WhatsNewBanner />
      {false && <DocsBanner /> }
      </> 
      }
      { 
      !isMobile && 
      <img className="hero-img block mx-auto w-full rounded-xl m-3 mt-5 border border-gray-300/50" src={demoGif} alt="Demo" /> 
      }
  
    </div>
  );
}

const Section = ({children, classes}: {children: React.ReactNode, classes?:string}) => {
  return (
    <div className={classNames("flex md:flex-row flex-col w-full", classes ?? "")}>
      {children}
    </div>
  );
}

const AppLikeTopBar = () => {
  return (<div className="bg-[#6078db] text-center items-center flex flex-wrap justify-center font-mono py-1 text-xs text-white">
    <span>👾 We are hiring a NodeJS Systems Engineer in New York. Apply <a className="underline" target="_blank" href="https://forms.gle/13ydy8FG44qK1ZL19" rel="noreferrer">here</a>.</span>
  </div>);
}

const MailingListBanner = () => {
  return (
    <div className="w-full items-center flex justify-center bg-blue-500/10">
      { <div className="p-1 px-2 rounded text-center bg-purple-500/10 border border-purple-500/50 text-purple-100  m-2 text-sm space-y-2 flex flex-col lg:mx-auto justify-center">
          {/* <div className="p-1 rounded bg-white/20">⭐️ New Repository: lmstudio-ai/examples</div>  */}
          {/* <div>Check out the new <a target="_blank" rel="noreferrer" href="https://github.com/lmstudio-ai/examples" className="underline">LM Studio "examples" GitHub repo</a>. Contributors are invited!</div>
          <div>The 'examples' repo contains sample code and integrations that work well with LM Studio.</div> */}
          
          <div className=" hover:text-white text-purple-400 active:text-gray-200/20 p-0 text-center rounded-lg flex flex-row items-center space-x-2 justify-center">
            <p>🔔</p>
            <a href={GOOGLE_FORM_URL}
              target="_blank"
              rel="noreferrer"
              className="text-sm underline whitespace-nowrap "
            >Sign up for new version email updates</a>
          </div>
      </div>}
    </div>
  )
}

// const HiringBanner = () => {
//   return (
//     <div className={classNames("w-full items-center flex justify-center border-b border-white/10")}>
//       { <div className="p-1 px-2 rounded text-center bg-sky-500/10 border border-sky-500/50 text-sky-100  m-2 text-sm space-y-2 flex flex-col lg:mx-auto justify-center space-x-2">
//           <div className=" hover:text-white text-sky-300 active:text-gray-200/20 p-0 text-center rounded-lg flex flex-row items-center space-x-[0.25rem] justify-center">
//             <a href="careers" target="_blank" rel="noreferrer" className="underline">🛠️ We're hiring TypeScript and C++ Engineers</a>
//             <span></span>
//           </div>
//       </div>}
//     </div>
//   )
// }

function QuestionAndAnswer ({question, answer}: {question: string, answer: string|React.ReactNode}) {
  const id = question.replace(/\s+/g, '-').toLowerCase(); // Generate an id by replacing spaces with hyphens and making it lower case

  return (
    <div id={id} className="flex flex-col space-y-2">
      <div className="text-white text-md flex flex-row space-x-2 items-center">
        <a href={`#${id}`} className="mr-1"><FaLink className="font-thin text-sm" /></a> {/* Add a link icon next to the question */}
        <p className="text-lg">{question}</p>
      </div>
      <div className="text-white/70">{answer}</div>
    </div>
  );
}


function QandA() {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) elem.scrollIntoView();
      } else {
        window.scrollTo({top: 0, left: 0})
      }
    }, 0);
  }, [location,]);

  return (
    <div className="flex flex-col space-y-4 w-full p-5">
      
      <div className="flex flex-col space-y-10 mx-auto">
        <div className="text-xl font-medium text-white flex flex-row space-x-3 items-center" id="faq"><a href={`#faq`} className="mr-3"><FaLink className="font-thin text-sm" /></a>Frequently Asked Questions</div>
        <div className="p-1 px-2 rounded text-center bg-purple-500/10 border border-purple-500/50 text-purple-100 text-sm space-y-2 flex flex-col justify-center">
          
          <div><b>TLDR:</b> The app does not collect data or monitor your actions. Your data stays local on your machine. It's free for personal use. For business use, please get in touch.</div>
        </div>

        <QuestionAndAnswer
          question="Does LM Studio collect any data?"
          answer=
          {
            <div><b>No.</b> One of the main reasons for using a local LLM is privacy, and LM Studio is designed for that. Your data remains private and local to your machine.</div>
          }
        />

        <QuestionAndAnswer
          question="Can I use LM Studio at work?"
          answer={
           <p>Please fill out <a className="underline hover:text-white transition-colors duration-300" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSd-zGyQIVlSSqzRyM4YzPEmdNehW3iCd3_X8np5NWCD_1G3BA/viewform?usp=sf_link" rel="noreferrer">the LM Studio @ Work request form</a> and we will get back to you as soon as we can. Please allow us some time to respond.</p>
          }
        />

        <QuestionAndAnswer
          question="What are the minimum hardware / software requirements?"
          answer={
            <ul className="list-disc list-inside pl-5">
              <li>Apple Silicon Mac (M1/M2/M3) with <b>macOS 13.6 or newer</b></li>
              <li>Windows / Linux PC with a processor that supports <b>AVX2</b> (typically newer PCs)</li>
              <li>16GB+ of RAM is recommended. For PCs, 6GB+ of VRAM is recommended</li>
              <li>NVIDIA/AMD GPUs supported</li>
            </ul>
          }
        />

        <QuestionAndAnswer
          question="Are you hiring?"
          answer={
            <div>
              Yes! See our <a href="careers" target="_blank" rel="noreferrer" className="underline">careers page</a> for open positions. We are a small team located in Brooklyn, New York, USA.
            </div>
          }
        />
      </div>
    </div>
  )
}

function App() {  
  // const isMobile = useIsMobile();

  // const coinFlip = Math.random() < 0.5;

  return (
    <HelmetProvider>
      <div className={classNames("h-screen flex flex-col justify-between gap-y-0 ", bottomPageBg)}>
        <Section classes="flex-col flex md:flex-col">
          <AppLikeTopBar />        
          {/* <HiringBanner /> */}
          {/* <WhatsNewBanner /> */}
          
          <div className={classNames("w-full flex flex-col", heroBarBg)}>
            <div className={
              classNames("p-3 flex flex-col container mx-auto xl:flex-row ")
            }>
              <LeftPane />
              <RightPane />
            </div>
          </div>
        </Section>


        <MailingListBanner />

        <Section classes="p-2 items-center bg-blue-500/10">
          <SocialChannels /> 
        </Section>


        <Section classes="flex-grow w-full justify-center items-center p-3 py-7">
          <div className="p-5 bg-blue-400/20 rounded-md border border-white/50 ">
            <span className="font-medium text-purple-300 lg:text-xl text-sm">With LM Studio, you can ...</span> 
            <div className="flex flex-col space-y-4 xl:p-3 p-2 text-white/90 lg:text-xl text-xs">
              <span>🤖 - Run LLMs on your laptop, entirely offline</span> 
              <span>👾 - Use models through the in-app Chat UI or an OpenAI compatible local server</span>
              <span>📂 - Download any compatible model files from HuggingFace 🤗 repositories</span> 
              <span>🔭 - Discover new &amp; noteworthy LLMs in the app's home page</span> 
              <p className="text-sm text-white/50">LM Studio supports any {ggmlLink} Llama, MPT, and StarCoder model on Hugging Face {exampleModels}</p>
              <p className="text-sm text-white/50">Minimum requirements: M1/M2/M3 Mac, or a Windows PC with a processor that supports AVX2. Linux is available in beta.</p>
              <p className={tertiaryTextColor}>
                Made possible thanks to the <a href="https://github.com/ggerganov/llama.cpp" target="_blank" rel="noreferrer" className="underline">llama.cpp</a> project.
              </p>    

              <p className={tertiaryTextColor}>
                We are expanding our team. See our <a href="careers" target="_blank" rel="noreferrer" className="underline">careers page</a>.
              </p>

              <p className={tertiaryTextColor}>
                Consult the Technical Documentation at <a href="docs" target="_blank" rel="noreferrer" className="underline">https://lmstudio.ai/docs</a>.
              </p>    
            </div>
          
          </div>
          
        </Section>

        {false && <Section classes="flex-grow w-full justify-center items-center p-3 py-7 min-h-[400px]">
            <h1>LMS</h1>
            <div className="flex xl:flex-row flex-col w-full h-full">
              <div className="flex flex-col space-y-3 h-full bg-blue-400 w-full">
                <p className="text-white/80">LM Studio's CLI, <code className="p-1 px-2 bg-blue-300/20 border border-white/20">lms</code>, is now available for download.</p>
              </div>

              <div className="flex flex-col space-y-3 h-full bg-green-400 w-full">
                <p className="text-white/80">LM Studio's CLI, <code className="p-1 px-2 bg-blue-300/20 border border-white/20">lms</code>, is now available for download.</p>
              </div>
            </div>
        </Section> }
        

        <Section classes={classNames("p-2 items-center", heroBarBg)}>
          <QandA />
        </Section>
        
        <Section classes='justify-center bg-black text-white/50 p-2 text-center lg:space-x-5'>
          <p>© LM Studio 2023 - 2024</p>
          <a href="terms" className="underline">Terms of Use</a>
          <a href="docs" className="underline">Documentation</a>
          <a href="blog" className="underline">Blog</a>
          <a href="https://github.com/lmstudio-ai" className="underline">GitHub</a>
          <a href="enterprise.html" className="underline">Can I use LM Studio at Work?</a>
          <a href="careers" className="underline">Careers</a>
          {/* <a href="beta-releases.html" className="underline">Beta Releases</a> */}
        </Section>
      </div>
    </HelmetProvider>
  );
}

export default App;
