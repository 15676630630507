import { DISCORD_URL } from "../App";
import discordLogo from "../discord.svg";

import { classNames } from "../utils";

const TopBar = () => {
  return (
    <div className={
      classNames("w-full h-[60px] justify-between flex flex-row items-center p-3 bg-[#01040A] border-b border-gray-200/20")
    }>
      <div className="flex flex-row space-x-1.5 font-medium items-center text-[1.05rem] cursor-pointer"
        onClick={() => window.location.href = "/"}
      >
        <span className="text-xl">👾</span><span>LM Studio</span>
      </div>

      <a href={DISCORD_URL} target="_blank" rel="noreferrer">
        <div className={
          classNames(
            "flex flex-row space-x-2 rounded border p-2.5 py-2.5 text-xs items-center",
            "bg-gray-700/50 hover:bg-blue-400/50 border-gray-600"
          )}>
          <img src={discordLogo} alt="Discord" className="w-5 h-5" />
          <span>Community</span>
        </div>
      </a>
    </div>
  );
}

const Position = ({ background, title, bullets }
  : { background: string, title: string, bullets: string[] }) => {
  return ( 
    <div className={classNames("flex md:space-y-0 space-y-5 md:flex-row flex-col justify-between h-full p-3 rounded border-gray-400/70 border ", background)}>
      <div className="flex flex-col space-y-3 w-full">
        <p className="text font-medium text-purple-300">{title}</p>
        <ul className="flex flex-col text-white/90 space-y-1.5 w-full">
          {bullets.map((bullet, i) => (
            <li key={i} className="text-xs">• {bullet}</li>
          ))}
        </ul>
      </div>
      <div className="md:w-fit md:items-center items-end justify-end flex flex-col bg-blue-900/0">
      <a
        className="p-3 bg-blue-700 hover:bg-blue-800 md:w-fit w-full justify-center border border-gray-50/40 rounded flex flex-row whitespace-nowrap cursor-pointer"
        href={`mailto:apply@lmstudio.ai`}
        target="_blank"
        rel="noreferrer"
      >
        Apply 📨 ↗
      </a>
      </div>
    </div>
  )
}

export const Careers = () => {
  return (
    <div className="md:fixed md:inset-0 w-full h-full bg-[#0B0F19] overflow-auto md:text text-sm">
      <div className="text-white p-0 h-full flex flex-col w-full bg-[#0B0F19] justify-between">
        <TopBar />
        <div className="w-full justify-center flex flex-col items-center h-full p-5">
          <div className="flex max-w-2xl flex-col bg-[#101827] rounded-xl border border-white/50 h-fit font-mono bg-gray-700/20">
            
            <div className={classNames(" bg-black/30 border-b border-white/50 w-full p-3 rounded-t-xl flex flex-row justify-between items-center")}>  
              <p className="p-0 m-0 font-medium font-mono text-purple-300">Build LM Studio With Us</p>
              {/* <p className="text p-0 m-0 font-medium font-mono">Open Positions</p> */}
              <img className="block w-[25px] shadow" src="/assets/android-chrome-192x192.png" alt="LM Studio Logo" />
            </div>

            

            <div className="p-5 bg-black/0 opacity-100 text-blue-300 text-center  border border-white/0">
              <p>Make AI technologies accessible and useful.</p>
            </div>

            
          
            <div className={classNames("bg-black/30 border-white/50 border-b border-t w-full p-3 flex flex-row justify-between")}>  
              <p className="text p-0 m-0 font-medium font-mono text-white">Open Positions</p>
              <p className="text p-0 m-0 font-medium font-mon text-purple-300">in Brooklyn, New York</p>
            </div>
          
            <div className={
              classNames(
                "flex justify-between max-w-2xl mx-auto flex-col space-y-5 p-3 py-5 w-full min-h-[200px]"
              )
            }>

              <Position 
                background="bg-[#1A2B4C]" 
                title="[C++] Systems Software Engineer" 
                bullets={
                  [
                    "Develop and maintain core systems code for LM Studio",
                    "Required: experience in OS-level systems programming in C++17 or newer",
                    "medical/dental/vision insurance, intensely interesting technical problems, access to awesome hardware, office in Brooklyn",
                  ]}
              />

              <Position 
                background="bg-[#1A2B4C]" 
                title="Node/React/TypeScript Software Engineer" 
                bullets={
                  [
                    "develop React UIs, Node.js systems code, and TypeScript libraries",
                    "required: deep understanding of JS/TS. Keen interest in system internals",
                    "medical/dental/vision insurance, intensely interesting technical problems, access to awesome hardware, office in Brooklyn",
                  ]
                }
              />
{/* 
              <Position 
                background="bg-[#1A2B4C]" 
                title="[TypeScript] SDK Software Engineer" 
                bullets={
                  [
                    "Develop and maintain core TypeScript libraries",
                    "Required: deep understanding of TypeScript and Node.js",
                  ]
                }
              /> */}

              {/* <Position 
                background="bg-[#1A2B4C]" 
                title="[Python/C++] LLM Infrastructure Software Engineer"
                bullets={
                  [
                    "Develop and maintain infrastructure for LLMs",
                    "Required: experience designing & implementing large-scale web systems",
                  ]
                }
              /> */}
            </div>  
          </div>
          <div className="text-center text-xs p-3 font-mono space-y-1.5 pt-5">
            <div>Please send your resume, a quick blurb about yourself, and a link to something you've built.</div>
            <div>Looking forward to hearing from you! Please allow us some time to respond.</div>
          </div>
        </div>
        <p className="text-center text-xs p-3 bg-[#01040A] border-t border-gray-200/20 text-gray-400/60">
          © 2024 Element Labs Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
};
